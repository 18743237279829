import {ThunkDispatch} from 'hooks/useThunkReducer';
import {ApiError} from 'utils/api';

// Transcript types
export type PreviewLoadingAction = {
  type: 'preview_loading_action';
};

export type PreviewReceiveSuccessAction = {
  type: 'preview_receive_success_action';
  payload: PreviewData;
};

export type PreviewReceiveErrorAction = {
  type: 'preview_receive_error_action';
  payload: string;
};

export type PreviewJobMatchData = {
  location: string;
  company_name: string;
  title: string;
  url: string;
  summary: string;
  external_url: string;
  external_job_id: string;
  certifications: string;
  compensation: string;
  contract_duration: string;
  disciplines: string;
  city: string;
  zipcode: string;
  state: string;
  country: string;
  job_id: string;
  sense_url: string;
  description: string;
  html: string;
};

export type PreviewMessage = {
  user_type: 'bot' | 'candidate';
  text: string;
  is_autofilled?: boolean;
  job_matches: Array<PreviewJobMatchData>;
};

export type PreviewData = Array<PreviewMessage>;

export type Transcript = {
  error: null | string;
  loading: boolean;
  data: PreviewData;
};

// This is the default message handler for the frontend:
// the 'text' argument acts as sidetone/local feedback that gets shown
// to the candidate. by default you can just use text and it will send
// that to the nlu but if you include an optional payload you can send
// down a rich payload (an array of strings for now)
export type HandlePostMessage = (
  text: string,
  payload?: string | string[],
) => unknown;

export type ChatId = {
  value?: string;
  type: 'flow_id' | 'chat_code';
};

export type SchedulerAttrs = {
  type: 'scheduler';
  template_id: string;
};

export type SummaryStatusAttrs = {
  type: 'summary';
  buttons: Array<ButtonData> | Array<AccessoryButtonData>;
};

export type FeedbackAttrs = {
  type: 'feedback';
  buttons: Array<ButtonData> | Array<AccessoryButtonData>;
};

export type MultiSelectAttrs = {
  type: 'multi-select';
  buttons: Array<ButtonData> | Array<AccessoryButtonData>;
  metadata: {
    event_name: string; // usually the node name
    event_type: string; // typically 'sense_form_action'
    node_id: number;
    response_type: string; // literal 'string' requires array response
    // because the nlu doesn't know how to parse
    // a string response. other response types
    // correspond to preset questions we know how
    // to interpret fuzzily
  };
};

export type SingleSelectAttrs = {
  type: 'single-select';
  buttons: Array<ButtonData> | Array<AccessoryButtonData>;
};

export type JobMatchAttrs = {
  type: 'job_match';
  matches_count: number;
  max_matches: number;
  payload: Array<AccessoryJobData>;
  result_display_style: JobResultListStyle;
  show_all_jobs_button: boolean;
  link_to_job: boolean;
  allow_application: boolean;
  allow_redirect: true;
};

export type FileUploadAttrs = {
  type: 'file_upload';
  file_container: string;
  file_path: string;
  file_link: string;
};

export type AccessoryAttrs =
  | SchedulerAttrs
  | SummaryStatusAttrs
  | FeedbackAttrs
  | MultiSelectAttrs
  | JobMatchAttrs
  | FileUploadAttrs;

export type MessageBase = {
  text: string;
  text_draft_json?: {
    blocks: Array<{
      text: string;
    }>;
    entityMap: {
      [key: string]: {
        data: {
          [key: string]: string;
        };
        type: string;
      };
    };
  } | void;
  recipient_id: string;
  conversation_status?: 'done';
  message_id: string;
  metadata: {
    event_type: string;
  };
  user_type?: 'candidate' | 'bot';
};

export type PlainTextMessage = MessageBase & {type: 'plain-text'};

export type SchedularMessage = MessageBase & SchedulerAttrs;

export type SummaryStatusMessage = MessageBase & SummaryStatusAttrs;

export type FeedbackMessage = MessageBase & FeedbackAttrs;

export type MultiSelectMessage = MessageBase & MultiSelectAttrs;

export type JobMatchMessage = MessageBase & JobMatchAttrs;

export type FileUploadMessage = MessageBase & FileUploadAttrs;

export type DatePickerMessage = MessageBase & {
  type: 'datepicker';
  placeholder: TextData;
  initial_date: string;
};

export type ConversationEndMessage = MessageBase & {
  type: 'conversation-end';
};

// This is unused right now, replaced by the version below
export type OldLATWaitStart = MessageBase & {
  type: 'lat-wait-start';
  metadata: {
    waitMessage?: string;
    connectionMessage?: string;
  };
};

export type LATWaitStart = MessageBase & {
  type: 'lat-wait-start';
  // the MessageBase `.text` property is the "connection message"
  metadata: {
    event_name: string;
    event_type: 'lat_wait_start';
    node_id: string;
    response_type: unknown;
    slots: string[];
  };
  web_socket_url: string;
};

export type LATAgentJoin = MessageBase & {
  type: 'lat-agent-join';
  conversation_id: string;
  agency_id: string;
  metadata: {
    event_type: 'lat-agent-join';
    agent_id: string; // could be anything,
    agent_handle: string; // display name
    agent_avatar: string; // some gravatar or static url for now
  };
};

export type LATDisconnect = MessageBase & {
  type: 'lat-agent-drop';
  metadata: {
    event_type: 'lat-agent-drop';
    agent_id: string;
  };
};

export type LATMessage = MessageBase & {
  type: 'lat-agent-message';
  agency_id: string;
  conversation_id: string;
  metadata: {
    event_type: 'lat-agent-message';
    agent_id: string;
  };
};

export type SingleSelectMessage = MessageBase & SingleSelectAttrs;

export type ApiMessage =
  | PlainTextMessage
  | SchedularMessage
  | SummaryStatusMessage
  | FeedbackMessage
  | MultiSelectMessage
  | JobMatchMessage
  | FileUploadMessage
  | DatePickerMessage
  | SingleSelectMessage
  | ConversationEndMessage
  | LATWaitStart
  | LATMessage
  | LATAgentJoin
  | LATDisconnect;

export type IncomingMessage = ApiMessage & {
  id: string;
  timeCreated: Date;
  direction: 'incoming';
};

export type OutgoingMessage = {
  id: string;
  timeCreated: Date;
  direction: 'outgoing';
  text: string;
  message_id?: string;
};

export type Message = IncomingMessage | OutgoingMessage;

export type BrandingSettings = {
  logo?: string;
  color?: string;
  button_color?: string;
  favicon?: string;
  og_image?: string;
  display_name?: string;
  chatbot_bot_name?: string;
  chatbot_bot_tagline?: string;
  chatbot_initial_greeting?: string;
  chatbot_audio_enabled?: boolean;
  chatbot_avatar?: string;
  chatbot_bubble_color?: string;
  chatbot_font_color?: string;
  // not actually a brand setting but affects brand display
  chatbot_white_label?: boolean;
  chatbot_uses_logo_for_header?: boolean;
  chatbot_job_match_cta_label?: string;
  chatbot_banner_message?: {
    blocks: Array<{
      text: string;
    }>;
    entityMap: {
      [key: string]: {
        data: {
          [key: string]: string;
        };
        type: string;
      };
    };
  };
};

export type ApiConversation = {
  messages: Array<ApiMessage>;
  is_conversation_completed?: boolean;
  isPollResponse?: boolean;
};

export type ApiConversationStart = ApiConversation & {
  agency_id: string;
  conversation_id: string;
  first_name?: string;
  messages: Array<ApiMessage>;
  branding_settings: BrandingSettings;
  flow: FlowData;
};

export type Conversation = {
  agency_id: string;
  conversation_id: string;
  first_name?: string;
  messages: Array<Message>;
  branding_settings: BrandingSettings;
  flow: FlowData;
};

export type LATConfig = {
  show_lat_button: boolean;
  enable_keyword_trigger: boolean;
  target_flow_id?: number | null;
  target_flow_name?: string | null;
  button_label: string;
};

export type FlowData = {
  id: string;
  anchor_entity_type: string;
  branding_settings: BrandingSettings;
  dynamic_attributes?: {name: string; data_type: string}[];
  show_top_faqs: boolean;
  top_faq_ids: string;
  flow: {
    id: string;
    is_feedback_enabled: boolean;
    status: string; // success | building | etc
    use_case: string; // another enum
  };
  lat_config: LATConfig;
};

export type ButtonData = {
  title: string;
  payload: string;
};

export type CustomMessageData = {
  blocks: Array<{
    type: 'section';
    text?: TextData;
    accessory?: AccessoryData;
    callback?: {api: string};
    conversation_status?: 'done';
  }>;
};

export type WritebackData = {
  ats_field: boolean;
  field_name: string;
  field_value: string | null;
};

export type SchedulerBookingAccessory = {
  type: 'scheduler';
  template_id: string;
};

export type SummaryStatusAccessoryData = {
  type: 'summary';
  intent: 'inform';
  slot: string;
  options: Array<{payload: string; title: string}>;
};

export type DatePickerAccessoryData = {
  type: 'datepicker';
  placeholder: TextData;
  initial_date: string;
};

export type SaveDbAccessory = {
  type: 'savedb';
};

export type FeedbackAccessory = {
  type: 'feedback';
  text: TextData;
};

export type JobTitlePickerAccessory = {
  type: 'jobtitlepicker';
  text: TextData;
};

export type CompanyPickerAccessory = {
  type: 'companypicker';
  text: TextData;
};

export type MultiSelectAccessoryData = {
  type: 'multi-select';
  intent: string;
  slot: string;
  options: Array<AccessoryButtonData>;
};

export type JobMatchAccessoryData = {
  type: 'job_match';
  intent: string;
  slot: string;
  matches_count: number;
  max_matches: number;
  payload: Array<AccessoryJobData>;
};

export type FileUploadAccesoryData = {
  type: 'file_upload';
  file_path: string;
  file_container: string;
  intent: string;
  slot: string;
};

export type AccessoryJobData = {
  title: string;
  location: string;
  external_url: string;
  company_name: string;
  summary: string;
  description: string;
  url: string;
  sense_url: string;
  html: string;
  job_id: string;
  external_job_id: string;
};

export type AccessoryButtonData = {
  title: string;
  payload: string;
};

export type AccessoryData =
  | JobMatchAccessoryData
  | FileUploadAccesoryData
  | MultiSelectAccessoryData
  | DatePickerAccessoryData
  | SaveDbAccessory
  | FeedbackAccessory
  | JobTitlePickerAccessory
  | CompanyPickerAccessory
  | SchedulerBookingAccessory
  | SummaryStatusAccessoryData;

export type TextData =
  | {
      type: 'mrkdwn';
      text: string;
    }
  | {
      type: 'plain_text';
      text: string;
    };

export type Faq = {
  id: string;
  intent: string;
  question: string;
  answer: string;
};

// there are many more keys in the agency config
// but we should only add them as we know the client will use them.
export type SenseAgencyConfig = {
  chatbot_white_label_frontend: boolean;
};

// Live Agent Transfer types

export type LATAgent = {
  id: string;
  name: string;
  avatar_url: string;
  display_initials?: string; // inferred from name if not set
};

export type LATWaitStartConfig = {
  url: string;
  id: string;
};

export type WSBaseMessage = {
  message_id: string;
  conversation_id: string;
  timeCreated: Date;
};

export type WSAgentMessage = WSBaseMessage & {
  type: 'text';
  text: string;
};

export type WSWaitMessage = WSBaseMessage & {
  type: 'wait_time';
  metadata: {
    wait_time: string;
    queue_position: number;
  };
};

export type WSCloseMessage = WSBaseMessage & {
  type: 'close';
};

export type WSMessage = WSAgentMessage | WSWaitMessage | WSCloseMessage;

export type LATConversation = {
  id: string;
  messages: WSMessage[];
};

export type ChatbotState = {
  chatId: ChatId;
  sessionId: string;
  isLoading: boolean;
  isDone: boolean;
  isSms: boolean;
  error: ApiError | null;
  conversation: null | Conversation;
  inputValue: string;
  showJobsView: boolean;
  muteChime: boolean;
  file: File | null;
  showFaq: boolean;
  faqs: Array<Faq>;
  agencyConfig: SenseAgencyConfig;
  agents: {[id: string]: LATAgent};
  mode: ChatbotMode;
  webSocketUrl: null | string;
  showBanner: boolean;
  transcript: Transcript;
  poll: {
    isPolling: boolean;
    lastMessageId?: string;
    isSendPending: boolean;
  };
};

export type StartLoadingAction = {
  type: 'start_loading';
};
export type ClearConversationAction = {
  type: 'clear_conversation';
};
export type UpdateFileAction = {
  type: 'update_file';
  payload: File | null;
};
export type ReceiveConversationAction = {
  type: 'receive_conversation';
  payload: ApiConversation;
};
export type StartConversationAction = {
  type: 'start_conversation';
  payload: ApiConversationStart;
};
export type ToggleMuteChimeAction = {
  type: 'toggle_mute_chime';
  payload: boolean;
};
export type ReceiveSessionIDAction = {
  type: 'receive_session_id';
  payload: string;
};
export type PostMessageAction = {
  type: 'post_message';
  payload: string;
};
export type ReceiveFaqAction = {
  type: 'receive_faqs';
  payload: Array<Faq>;
};
export type EndConversationAction = {
  type: 'end_conversation';
};
export type ShowFaqAction = {
  type: 'toggle_show_faq';
};
export type JobsViewAction = {
  type: 'toggle_jobs_view';
  payload: boolean;
};
export type ReceiveAgencyConfigAction = {
  type: 'receive_agency_config';
  payload: SenseAgencyConfig;
};

export type SetChatbotModeAction = {
  type: 'set_chatbot_mode';
  payload: ChatbotMode;
};

// LAT Actions

export type ReceiveLATWaitAction = {
  type: 'receive_lat_wait';
  payload: LATWaitStartConfig;
};

export type ReceiveLATAgentAction = {
  type: 'receive_lat_agent';
  payload: LATAgent;
};

export type ReceiveLATConversationAction = {
  type: 'receive_lat_conversation';
  payload: LATConversation;
};

export type LATEnd = {};
export type ReceiveLATEndAction = {
  type: 'end_lat_conversation';
  payload: LATEnd;
};

// Errors

export type ReceiveErrorAction = {
  type: 'receive_error';
  payload: ApiError;
};

export type ReceiveConversationErrorAction = {
  type: 'receive_conversation_error';
  payload: ApiError;
};

type WSError = {};
export type ReceiveWSError = {
  type: 'receive_ws_error';
  payload: WSError;
};

export type StartPollAction = {
  type: 'start_poll';
};

export type StopPollAction = {
  type: 'stop_poll';
};

export type UpdatePollDataAction = {
  type: 'update_poll_data';
  payload: string;
};

export type ChatbotAction =
  | ReceiveConversationAction
  | ReceiveErrorAction
  | ReceiveConversationErrorAction
  | StartConversationAction
  | PostMessageAction
  | ClearConversationAction
  | StartLoadingAction
  | EndConversationAction
  | JobsViewAction
  | ReceiveSessionIDAction
  | ToggleMuteChimeAction
  | ShowFaqAction
  | ReceiveFaqAction
  | UpdateFileAction
  | ReceiveAgencyConfigAction
  | ReceiveLATWaitAction
  | ReceiveLATAgentAction
  | ReceiveLATConversationAction
  | ReceiveLATEndAction
  | ReceiveWSError
  | SetChatbotModeAction
  | PreviewLoadingAction
  | PreviewReceiveSuccessAction
  | PreviewReceiveErrorAction
  | StartPollAction
  | StopPollAction
  | UpdatePollDataAction;

//export type ChatbotDispatch = React.Dispatch<ChatbotAction>;
export type ChatbotDispatch = ThunkDispatch<ChatbotState, ChatbotAction>;

export type InboundDisplayMode = 'none' | 'min' | 'mid' | 'max';

export type ChatbotMode =
  | 'nlu' // regular conversation, default state
  | 'lat_wait'
  | 'lat'
  | 'lat_terminating'
  | 'lat_canceled'
  | 'loading' // TODO(marcos): replace isLoading with this
  | 'conversation_end'; // TODO(marcos) replace isDone with this

export type JobResultListStyle =
  | 'Numbered List'
  | 'No Styling'
  | 'Bulleted List';

export enum OpenVia {
  inputBox = 'input_box',
  containerKeypress = 'container_keypress',
}

export type DropdownOpenState = {
  isOpen: boolean;
  openVia: null | OpenVia;
};

export type DropdownOptionsHandlerParams = {
  e: React.KeyboardEvent;
  option: ButtonData;
  messageType: IncomingMessage['type'];
};

type KeyAction = (params: DropdownOptionsHandlerParams) => void;

export type KeyActions = {
  [key: string]: KeyAction;
};
