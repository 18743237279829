import React, {useCallback} from 'react';
import last from 'lodash/last';
import keyBy from 'lodash/keyBy';
import classnames from 'classnames';
import classify from 'utils/classify';

import {genericBotApi} from 'utils/bot-api';
import {isJobMatchQuestionNode} from 'utils/job';
import {
  ApiMessage,
  Message,
  ChatbotDispatch,
  ChatbotState,
  BrandingSettings,
  ChatId,
  IncomingMessage,
  ButtonData,
  InboundDisplayMode,
  HandlePostMessage,
  Transcript,
  DropdownOpenState,
  OpenVia,
  DropdownOptionsHandlerParams,
  KeyActions,
} from 'types';

// @ts-ignore
import {
  Editor,
  EditorState,
  convertFromRaw,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  // @ts-ignore
} from 'draft-js';

import {TextInputContext} from 'context/TextInputContext';
import postMultiSelectMessage from 'utils/postMultiSelectMessage';
import {Api} from 'utils/api';

import {useWindowWidth} from 'context/Responsive';
import {useBranding} from 'context/Branding';
import {useChatbotState} from 'context/ChatbotState';

import TranscriptView from 'components/TranscriptView/TranscriptView';
import {NamedDesktopHeader} from 'components/Header/Header';
import {Thread, MessageContainer} from 'components/Thread/Thread';
import FilePreview from 'components/File/File';
import UnstyledButton from '../../@spaced-out/components/button/unstyled';
// @ts-ignore
import CheckboxButton from '../../@spaced-out/components/button/checkbox-button';

import {ReactComponent as Caret} from 'images/chevron-up.svg';
import {ReactComponent as CloseIcon} from 'images/close.svg';

import botCss from './Chatbot.module.css';
import botLogo from 'images/powered-by.png';
import tCss from 'components/Thread/Thread.module.css';

import Linkify from 'linkify-it';

const linkify = Linkify();

export default function Chatbot({
  chatId,
  state,
  dispatch,
  chatbotApiV2,
  mode,
  isPreview,
  branding_settings,
  inboundDisplayMode,
  maximizeInboundDisplay,
  chatbotFaqEnabled,
  onPostMessage,
  qnOverSms = false,
  jobSelected = false,
}: {
  chatId: ChatId;
  state: ChatbotState;
  dispatch: ChatbotDispatch;
  chatbotApiV2: boolean;
  mode?: 'bot';
  isPreview: boolean;
  branding_settings: BrandingSettings;
  inboundDisplayMode?: InboundDisplayMode;
  maximizeInboundDisplay: () => void;
  chatbotFaqEnabled?: boolean;
  onPostMessage: HandlePostMessage;
  qnOverSms?: boolean;
  jobSelected?: boolean;
}) {
  const {context: botContext} = useWindowWidth();
  const {first_name} = state.conversation ?? {};

  const lastMessage =
    Array.isArray(state?.conversation?.messages) &&
    last(state?.conversation?.messages); // ?. for typescript

  const inputDisabled =
    state.isDone ||
    !lastMessage ||
    lastMessage.direction !== 'incoming' ||
    lastMessage.type === 'scheduler';

  return (
    <div className={botCss.root}>
      {botContext === 'desktop' && (
        <NamedDesktopHeader
          name={branding_settings.chatbot_bot_name ?? 'Reva'}
          description={
            branding_settings.chatbot_bot_tagline ??
            'Recruiting Virtual Assistant'
          }
          state={state}
          dispatch={dispatch}
          chatbotFaqEnabled={chatbotFaqEnabled}
          inputDisabled={inputDisabled}
          onPostMessage={onPostMessage}
        />
      )}

      {state.conversation && (
        <ConversationView
          messages={state.conversation.messages}
          transcript={state.transcript}
          firstName={first_name}
          isLoading={state.isLoading}
          isDone={state.isDone}
          brandingSettings={state.conversation.branding_settings}
          dispatch={dispatch}
          onPostMessage={onPostMessage}
          mode={mode}
          maximizeInboundDisplay={maximizeInboundDisplay}
          qnOverSms={qnOverSms}
          jobSelected={jobSelected}
        />
      )}
    </div>
  );
}

function ConversationView({
  messages,
  transcript,
  firstName,
  isLoading,
  isDone,
  dispatch,
  brandingSettings,
  onPostMessage,
  mode,
  maximizeInboundDisplay,
  qnOverSms = false,
  jobSelected = false,
}: {
  messages: Message[];
  transcript: Transcript;
  firstName: string | void;
  isLoading: unknown;
  isDone: unknown;
  brandingSettings: BrandingSettings;
  dispatch: ChatbotDispatch;
  onPostMessage: (text: string, payload?: string | string[]) => unknown;
  mode?: 'bot';
  maximizeInboundDisplay: () => void;
  qnOverSms?: boolean;
  jobSelected?: boolean;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const {state} = useChatbotState();
  const file = state?.file;
  const [value, setValue] = React.useState<string>('');
  const [jsonValue, setJsonValue] = React.useState<string[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const textInputContextValue = React.useMemo(
    () => ({value, setValue, jsonValue, setJsonValue}),
    [value, jsonValue],
  );

  // NOTE (kyle): when a new message comes in, we scroll to the bottom.
  React.useLayoutEffect(() => {
    const {current} = containerRef;
    if (current) {
      setTimeout(() => {
        current.scrollTop = current.scrollHeight;
      }, 0);
    }
  }, [messages]);

  // NOTE (kyle): resize is mostly triggered by a keyboard popping up
  // so we pin the thread to the bottom when this happens.
  // ideally we'd just use `flex-flow: column-reverse` to
  // solve this with CSS, but FireFox fails to render this property
  // properly.
  React.useEffect(() => {
    const handleResize = () => {
      const {current} = containerRef;
      if (current) {
        current.scrollTop = current.scrollHeight;
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollThreadToCurrent = () => {
    const {current} = containerRef;
    if (current) {
      setTimeout(() => {
        current.scrollTop = current.scrollHeight;
      }, 0);
    }
  };

  // NOTE (kyle): this code allows us to keep the scroll of the element
  // at the bottom unless the user has explicitly scrolled up.
  // NOTE (iris): removing for now unless we decide to set scroll behavior like this again
  // const {current: containerElement} = containerRef;
  // const isAtBottom =
  //   !containerElement ||
  //   containerElement.scrollHeight - containerElement.scrollTop ===
  //     containerElement.clientHeight;

  const lastMessage = last(messages);
  const lastMessageAccessory =
    lastMessage?.direction === 'incoming' && lastMessage;

  const handleUploadFile = (accessory: ApiMessage) => {
    if (!file || accessory.type !== 'file_upload') {
      return;
    }

    setIsSubmitting(true);

    genericBotApi
      .get('/file-access-proxy/upload', {
        file_container: accessory.file_container,
        file_path: accessory.file_path,
        file_name: file.name,
      })
      .then(data => {
        const externalApi = new Api(data.url);
        const form = new FormData();
        for (let key in data.fields) {
          form.append(key, data.fields[key]);
        }
        form.append('file', file);

        return externalApi.post('/', form, {multipart: true});
      })
      .then(data => {
        onPostMessage(
          file.name,
          JSON.stringify({
            file_container: accessory.file_container,
            file_path: accessory.file_path,
            file_name: file.name,
            file_link: accessory.file_link,
          }),
        );
        setValue('');
        setIsSubmitting(false);
      })
      .catch(() => setIsSubmitting(false));
  };

  const handlePostMessage = () => {
    if (value) {
      let payload;
      if (
        lastMessageAccessory &&
        lastMessageAccessory.type === 'multi-select'
      ) {
        postMultiSelectMessage(
          lastMessageAccessory,
          value,
          onPostMessage,
          val => (Array.isArray(val) ? setJsonValue(val) : setValue(val)),
        );
        return;
      }

      if (lastMessageAccessory && lastMessageAccessory.type === 'file_upload') {
        handleUploadFile(lastMessageAccessory);
        return;
      }

      onPostMessage(value, payload);
      setValue('');
    }
  };

  const disabled = qnOverSms
    ? messages.length === 0
    : state?.mode === 'nlu' &&
      (isDone ||
        !lastMessage ||
        lastMessage.direction !== 'incoming' ||
        lastMessage?.type === 'scheduler' ||
        (lastMessage?.direction === 'incoming' &&
          lastMessage.type === 'job_match' &&
          isJobMatchQuestionNode(lastMessage)));
  // lat_wait preempts disabled, whenever we're waiting to connect
  // the chatbot remains "active" so people can queue messages for
  // the agent
  // disable input if last node is job match, and it is a question node

  const isLongMultipleChoice =
    lastMessageAccessory &&
    lastMessage?.direction === 'incoming' &&
    (lastMessageAccessory.type === 'multi-select' ||
      lastMessageAccessory.type === 'single-select') &&
    lastMessageAccessory.buttons.length > 5;

  const isFileUpload =
    lastMessageAccessory &&
    lastMessage?.direction === 'incoming' &&
    lastMessageAccessory.type === 'file_upload';

  return (
    <TextInputContext.Provider value={textInputContextValue}>
      {state?.showFaq ? (
        <Faq dispatch={dispatch} onPostMessage={onPostMessage} />
      ) : (
        <div ref={containerRef} className={botCss.conversationContainer}>
          <TranscriptView
            messagesLength={messages.length}
            transcript={transcript}
            onSubmit={onPostMessage}
            qnOverSms={qnOverSms}
            jobSelected={jobSelected}
          />
          <Thread
            className={botCss.thread}
            firstName={firstName}
            messages={messages}
            onPostMessage={onPostMessage}
            isLoading={isLoading}
            dispatch={dispatch}
            inputValue={value}
            isLongMultipleChoice={isLongMultipleChoice}
            scrollThreadToCurrent={scrollThreadToCurrent}
          />
        </div>
      )}

      {brandingSettings?.chatbot_banner_message && state?.showBanner && (
        <ReadOnlyDraftEditor
          rte_json={brandingSettings.chatbot_banner_message}
          className={botCss.bannerMessage}
        />
      )}

      <TextInput
        isDone={isDone}
        disabled={disabled || isSubmitting}
        onSubmit={handlePostMessage}
        onChange={setValue}
        value={value}
        mode={mode}
        isLongMultipleChoice={isLongMultipleChoice}
        isFileUpload={isFileUpload}
        message={lastMessageAccessory ? lastMessageAccessory : undefined}
        onPostMessage={onPostMessage}
        maximizeInboundDisplay={maximizeInboundDisplay}
        dispatch={dispatch}
      />
    </TextInputContext.Provider>
  );
}

function Faq({
  dispatch,
  onPostMessage,
}: {
  dispatch: ChatbotDispatch;
  onPostMessage: (text: string, payload?: string | string[]) => unknown;
}) {
  const flowBrandSettings = useBranding();
  const {state} = useChatbotState();
  const topFaqIds = state?.conversation?.flow.top_faq_ids;
  const allFaqs = state ? keyBy(state.faqs, 'id') : {};
  const topFaqs = state
    ? topFaqIds?.split(',').map(id => allFaqs[id] || {})
    : [];

  return (
    <div className={botCss.thread}>
      <div className={classnames(tCss.root, botCss.faqContainer)}>
        <p className={tCss.timestampSubtitle}>
          {new Date().toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            weekday: 'long',
          })}
        </p>
        <MessageContainer
          isIncoming
          isFirstInGroup
          firstName={''}
          avatar={
            <img
              alt="Chatbot avatar"
              className={tCss.favicon}
              src={flowBrandSettings.chatbot_avatar}
            />
          }
          branding_settings={flowBrandSettings}
        >
          <div
            className={classnames(tCss.message, tCss.incoming, tCss.last)}
            style={{
              color: flowBrandSettings.chatbot_font_color,
              backgroundColor: flowBrandSettings.chatbot_bubble_color,
            }}
          >
            <p className={tCss.bubbleIncoming}>
              Do you need help? Here are the most frequently asked questions.
            </p>
          </div>
        </MessageContainer>
        <div className={botCss.faqList}>
          {topFaqs?.map(faq => (
            <div
              className={classnames(tCss.bubble, botCss.faqQuestion)}
              onClick={() => {
                faq.question && onPostMessage(faq.question);
                dispatch({
                  type: 'toggle_show_faq',
                });
              }}
              style={{
                color: flowBrandSettings.button_color,
                border: `2px solid ${flowBrandSettings.button_color}`,
              }}
            >
              {faq.question}
            </div>
          ))}
          <div className={botCss.faqTypeYourOwn}>
            Or type your own question...
          </div>
        </div>
      </div>
    </div>
  );
}

function TextInput({
  isDone,
  disabled,
  onSubmit,
  value,
  onChange,
  mode,
  isLongMultipleChoice,
  message,
  onPostMessage,
  maximizeInboundDisplay,
  isFileUpload,
  dispatch,
}: {
  isDone: unknown;
  disabled: unknown;
  value: string;
  onChange: (val: string) => unknown;
  onSubmit: () => unknown;
  mode?: 'bot';
  isLongMultipleChoice: boolean;
  message: IncomingMessage | void;
  onPostMessage: HandlePostMessage;
  maximizeInboundDisplay: () => void;
  isFileUpload: boolean;
  dispatch: ChatbotDispatch;
}) {
  const brandingSetting = useBranding();
  const {state, agencyConfig} = useChatbotState();
  const {context: botContext} = useWindowWidth();

  const inputRef = React.useRef<HTMLElement | null>();

  const handleInputRef = useCallback(
    (element: HTMLElement | null) => {
      if (
        element &&
        !disabled &&
        // on mobile we don't want to autofocus the input because it ruins
        // the initial layout (in desktop we do)
        ['sourcing', 'desktop'].includes(botContext) &&
        document.activeElement !== element
      ) {
        element.focus();
      }
      inputRef.current = element;
    },
    [disabled, botContext],
  );

  const {jsonValue, setJsonValue} = React.useContext(TextInputContext);

  const selectedChoices = new Set<string>(jsonValue.filter(Boolean));

  const handleLongMultipleChoiceSubmit = () => {
    if (message && message.type === 'multi-select') {
      postMultiSelectMessage(message, jsonValue, onPostMessage, v =>
        Array.isArray(v) ? setJsonValue(v) : onChange(v),
      );
    } else {
      const label =
        message && message.type === 'single-select'
          ? message.buttons.find(({payload}) => value === payload)?.title ??
            value
          : '';

      onPostMessage(label, [value]);
      onChange('');
    }
  };

  return (
    <div
      className={classnames(
        botCss.textInputWrapper,
        mode === 'bot' && botCss.hasBotBranding,
      )}
    >
      <div className={botCss.textInputInner}>
        <ChatInput
          message={message}
          selectedChoices={selectedChoices}
          maximizeInboundDisplay={maximizeInboundDisplay}
          disabled={Boolean(disabled)}
          isDone={Boolean(isDone)}
          handleInputRef={handleInputRef}
          value={value}
          isLongMultipleChoice={isLongMultipleChoice}
          isFileUpload={isFileUpload}
          onSubmit={onSubmit}
          onChange={onChange}
          dispatch={dispatch}
        />

        <UnstyledButton
          className={classnames(botCss.button, !disabled && botCss.enabled)}
          aria-label="Send message"
          disabled={disabled || (jsonValue.length === 0 && !value)}
          onClick={() => {
            if (state?.showFaq) {
              dispatch({
                type: 'toggle_show_faq',
              });
            }
            return isLongMultipleChoice
              ? handleLongMultipleChoiceSubmit()
              : onSubmit();
          }}
          onPointerDown={event => {
            // NOTE (kyle): prevents the input from blurring
            if (document.activeElement === inputRef.current) {
              event.preventDefault();
            }
          }}
        >
          <SendIcon
            color={brandingSetting.button_color}
            className={classnames(
              botCss.sendIcon,
              disabled ? botCss.sendIconDisabled : '',
            )}
          />
        </UnstyledButton>
      </div>
      {mode === 'bot' && !agencyConfig?.chatbot_white_label_frontend && (
        <a
          className={botCss.senseBranding}
          href="https://www.sensehq.com/product/ai-chatbot"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={botLogo} alt="powered by sense" width={93} height={12} />
        </a>
      )}
    </div>
  );
}

function ChatInput({
  message,
  selectedChoices,
  maximizeInboundDisplay,
  disabled,
  isDone,
  handleInputRef,
  value,
  isLongMultipleChoice,
  isFileUpload,
  onSubmit,
  onChange,
  dispatch,
}: {
  message: IncomingMessage | void;
  selectedChoices: Set<string>;
  maximizeInboundDisplay: () => void;
  disabled: boolean;
  isDone: boolean;
  handleInputRef: (element: HTMLElement | null) => void;
  value: string;
  isLongMultipleChoice: boolean;
  isFileUpload: boolean;
  onSubmit: () => unknown;
  onChange: (val: string) => unknown;
  dispatch: ChatbotDispatch;
}) {
  const {state} = useChatbotState();
  if (isLongMultipleChoice && message) {
    return (
      <MultipleChoiceInput
        message={message}
        selectedChoices={selectedChoices}
        maximizeInboundDisplay={maximizeInboundDisplay}
      />
    );
  } else if (isFileUpload && message) {
    return <FileUploadInput />;
  } else {
    return (
      <textarea
        className={botCss.textarea}
        placeholder={!disabled ? 'Type a message...' : ''}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (state?.showFaq) {
              dispatch({
                type: 'toggle_show_faq',
              });
            }
            onSubmit();
          }
        }}
        onChange={
          !disabled ? event => onChange(event.currentTarget.value) : undefined
        }
        value={value}
        disabled={Boolean(disabled || isDone)}
        ref={handleInputRef}
        maxLength={800}
        onFocus={maximizeInboundDisplay}
      />
    );
  }
}

function MultipleChoiceInput({
  message,
  selectedChoices,
  maximizeInboundDisplay,
}: {
  message: IncomingMessage;
  selectedChoices: Set<string>;
  maximizeInboundDisplay: () => void;
}) {
  const brandingSetting = useBranding();
  const {jsonValue, setJsonValue, setValue, value} = React.useContext(
    TextInputContext,
  );

  const [dropdownState, setDropdownState] = React.useState<DropdownOpenState>({
    isOpen: false,
    openVia: null,
  });
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const dropdownOptionsRef = React.useRef<HTMLDivElement>(null);
  const [searchString, setSearchString] = React.useState('');

  React.useEffect(() => {
    const handleClickAway = (e: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(e.target as Node)
      ) {
        setDropdownState({isOpen: false, openVia: null});
      }
    };

    window.addEventListener('click', handleClickAway);

    return () => {
      window.removeEventListener('click', handleClickAway);
    };
  }, []);

  React.useEffect(() => {
    if (
      !dropdownState.isOpen ||
      !dropdownOptionsRef?.current?.firstElementChild ||
      !(dropdownOptionsRef.current?.firstElementChild instanceof HTMLElement)
    )
      return;

    // Avoid focusing on the list when opened whilst typing
    if (dropdownState.openVia === OpenVia.containerKeypress)
      dropdownOptionsRef.current.firstElementChild.focus();
  }, [dropdownState.isOpen, dropdownState.openVia]);

  const filteredOptions =
    message.type === 'multi-select' || message.type === 'single-select'
      ? message.buttons.filter(({title}) =>
          title.toLowerCase().includes(searchString.toLowerCase()),
        )
      : [];

  const keyActions: KeyActions = {
    ' ': ({e, option, messageType}) => {
      // Space - scrolls the list
      e.preventDefault();

      // Manually handle select / unselect
      jsonValue.includes(option.payload)
        ? handleRemoveToken(option)
        : handleSelectToken(option, messageType);
    },

    // Tab - moves to next item
    Tab: ({e}) => e.preventDefault(),

    // Close the dropdown
    Escape: () => {
      setDropdownState({isOpen: false, openVia: null});
      dropdownRef.current?.focus();
    },

    // Traverse the list
    ArrowDown: ({e}) => {
      const next = e?.currentTarget?.nextElementSibling;
      next instanceof HTMLElement && next.focus();
    },
    ArrowUp: ({e}) => {
      const prev = e?.currentTarget?.previousElementSibling;
      prev instanceof HTMLElement && prev.focus();
    },

    // Handle single select option selection separately
    Enter: ({option, messageType}) => {
      messageType === 'single-select' && handleSelectToken(option, messageType);
    },
  };

  const handleRemoveToken = (option: ButtonData) => {
    if (message.type === 'single-select') {
      setValue('');
    } else {
      selectedChoices.delete(option.payload);
      setJsonValue(Array.from(selectedChoices));
    }
  };

  const handleSelectToken = (option: ButtonData, messageType: string) => {
    if (searchString) {
      setSearchString('');
    }
    if (messageType === 'single-select') {
      setValue(option.payload);
      setDropdownState({isOpen: false, openVia: null});
    } else {
      selectedChoices.add(option.payload);
      setJsonValue(Array.from(selectedChoices));
    }
  };

  const handleOptionsKeyPress = ({
    e,
    option,
    messageType,
  }: DropdownOptionsHandlerParams) => {
    e.stopPropagation();

    const action = keyActions[e.key];
    if (typeof action === 'function') action({e, option, messageType});
  };

  const handleContainerKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      setDropdownState(state => ({
        isOpen: !state.isOpen,
        openVia: state.isOpen ? null : OpenVia.containerKeypress,
      }));
    }
  };

  const handleSelectedTokenKeyPress = (
    e: React.KeyboardEvent,
    payload: string,
  ) => {
    e.stopPropagation();
    if (e.key === ' ' || e.key === 'Enter')
      handleRemoveToken({
        payload,
        title: buttonTitleByPayload[payload]?.title || '',
      });
  };

  const buttonTitleByPayload =
    message.type === 'multi-select' || message.type === 'single-select'
      ? keyBy(message.buttons, 'payload')
      : {};

  let selected;
  if (message.type === 'single-select') {
    selected = value ? [value] : [];
  } else {
    selected = jsonValue;
  }
  return (
    <div
      className={botCss.dropdownContainer}
      ref={dropdownRef}
      tabIndex={0}
      onKeyDown={handleContainerKeyPress}
    >
      <div className={botCss.textarea}>
        <div>
          {selected.map(payload => (
            <div
              className={botCss.token}
              tabIndex={0}
              onClick={() =>
                handleRemoveToken({
                  payload,
                  title: buttonTitleByPayload[payload]?.title || '',
                })
              }
              onKeyDown={e => handleSelectedTokenKeyPress(e, payload)}
            >
              {buttonTitleByPayload[payload]?.title || ''}
              <span className={botCss.removeToken}>x</span>
            </div>
          ))}
        </div>
        {(!value || message.type === 'multi-select') && (
          <div className={botCss.dropdownInputContainer}>
            <input
              className={botCss.dropdownInput}
              placeholder="Select an option or type to search"
              value={searchString}
              onChange={e => {
                if (!dropdownState.isOpen) {
                  setDropdownState({isOpen: true, openVia: OpenVia.inputBox});
                }
                setSearchString(e.target.value);
              }}
              onClick={e => {
                maximizeInboundDisplay();
                setDropdownState({isOpen: true, openVia: OpenVia.inputBox});
              }}
              /* Container handles kbd events, so stop bubbling */
              onKeyDown={e => e.stopPropagation()}
              style={{color: 'red'}}
            />
            <div
              onClick={() => {
                setDropdownState(state => ({
                  isOpen: !state.isOpen,
                  openVia: state.isOpen ? null : OpenVia.inputBox,
                }));
              }}
            >
              <Caret
                className={classify(botCss.caret, {
                  [botCss.caretReversed]: dropdownState.isOpen,
                })}
                color={brandingSetting.button_color}
              />
            </div>
          </div>
        )}
      </div>
      {dropdownState.isOpen && (
        <div className={botCss.dropdownOptions} ref={dropdownOptionsRef}>
          {filteredOptions.map(option =>
            message.type === 'single-select' ? (
              <div
                tabIndex={0}
                className={botCss.dropdownOption}
                onClick={() => handleSelectToken(option, message.type)}
                onKeyDown={e =>
                  handleOptionsKeyPress({e, option, messageType: message.type})
                }
              >
                {option.title}
              </div>
            ) : (
              <CheckboxButton
                selected={jsonValue.includes(option.payload)}
                onClick={(e: any) => {
                  e.stopPropagation();

                  return jsonValue.includes(option.payload)
                    ? handleRemoveToken(option)
                    : handleSelectToken(option, message.type);
                }}
                onKeyDown={(e: React.KeyboardEvent) =>
                  handleOptionsKeyPress({e, option, messageType: message.type})
                }
                className={botCss.dropdownOption}
                style={{
                  backgroundColor: brandingSetting.button_color ?? '#007faf',
                  color: 'white',
                }}
              >
                {option.title}
              </CheckboxButton>
            ),
          )}
        </div>
      )}
    </div>
  );
}

function FileUploadInput() {
  const {state, dispatch} = useChatbotState();
  const file = state?.file;
  const [showFilePreview, setShowFilePreview] = React.useState(false);

  return (
    <>
      <div className={botCss.dropdownContainer}>
        <div className={botCss.textarea}>
          {file && (
            <>
              <div
                onClick={() =>
                  dispatch && dispatch({type: 'update_file', payload: null})
                }
                className={botCss.removeFile}
              >
                <CloseIcon />
              </div>
              <div>{file.name}</div>
              <div
                onClick={() => setShowFilePreview(true)}
                className={botCss.previewFile}
              >
                preview
              </div>
            </>
          )}
        </div>
      </div>
      {showFilePreview && (
        <FilePreview setShowFilePreview={setShowFilePreview} />
      )}
    </>
  );
}

const SendIcon = ({color, className}: {color?: string; className: string}) => (
  <svg
    className={className}
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="18.5" cy="18.5" r="18.5" fill={color} />
    <path
      d="M24.1712 10.4256L9.76995 18.7073C9.0306 19.1246 9.09489 20.216 9.86639 20.537L14.3989 22.3987V25.6729C14.3989 26.6679 15.5883 27.0852 16.1991 26.3469L18.16 23.9716L22.2103 25.6408C22.8211 25.8976 23.5283 25.5124 23.6248 24.8383L25.6821 11.4528C25.8107 10.6182 24.9106 9.97625 24.1712 10.4256ZM15.4276 25.6729V22.8481L17.1635 23.5543L15.4276 25.6729ZM22.6282 24.7099L15.9741 21.9493L22.4032 14.406C22.5639 14.2134 22.3068 13.9566 22.1139 14.1171L13.9167 21.1147L10.2843 19.6061L24.6856 11.2923L22.6282 24.7099Z"
      fill="white"
    />
  </svg>
);

export function ReadOnlyDraftEditor({
  rte_json,
  className,
}: {
  // @ts-ignore
  rte_json: RawDraftContentState;
  className: string;
}) {
  const decorator = new CompositeDecorator([
    hyperlinkReadOnlyDecorator,
    LinkDecoratorEntry,
  ]);

  const editorState = EditorState.createWithContent(
    convertFromRaw(rte_json),
    decorator,
  );

  const plainText = editorState.getCurrentContent().getPlainText();

  return plainText.length > 1 ? (
    <p className={className}>
      <Editor
        editorState={editorState}
        readOnly={true}
        onChange={() => {}}
        blockRendererFn={blockRenderer}
        blockStyleFn={blockStyleFn}
      />
    </p>
  ) : null;
}

function blockStyleFn(block: ContentBlock): string {
  switch (block.getType()) {
    case 'ordered-list-item':
      return 'custom-ordered-list';
    default:
      return '';
  }
}

const GifBlock = ({
  block,
  contentState,
}: {
  block: ContentBlock;
  contentState: ContentState;
}) => {
  const entityKey = block.getEntityAt(0);
  const altText =
    (entityKey && contentState.getEntity(entityKey)?.getData()?.altText) || '';
  const text = block.getText();
  const url = text.match(/\((.*?)\)/)?.[1];
  const [imageDisplay, setImageDisplay] = React.useState<React.ReactNode>(null);

  const blockKey = block.getKey();

  React.useEffect(() => {
    if (url) {
      checkIfImageExists(url, exists => {
        if (exists) {
          setImageDisplay(
            <img src={url} alt={altText} className={botCss.gif} />,
          );
        } else {
          console.error(`url is invalid: ${url}`);
        }
      });
    }
  }, []);

  return imageDisplay;
};

function blockRenderer(contentBlock: ContentBlock) {
  const type = contentBlock.getType();
  if (type === 'atomic') {
    return {
      component: GifBlock,
      editable: false,
    };
  }
}

function checkIfImageExists(url: string, callback: (bool: boolean) => void) {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

const HyperlinkReadOnlyComponent = ({
  children,
  contentState,
  entityKey,
}: {
  children: React.ReactNode;
  contentState: ContentState;
  entityKey?: string;
}) => {
  const entity = entityKey ? contentState.getEntity(entityKey).data : null;
  let url = entity?.url;
  const text = entity?.label ?? '';
  const metadata = entity?.metadata ?? null;
  const {state, releaseFlags} = useChatbotState();
  const conversation_id = state?.conversation?.conversation_id || null;

  // enrich url with conversation id
  if (
    metadata &&
    conversation_id &&
    releaseFlags?.chatbot_custom_link_generation
  ) {
    url = `${url}?conv_id=${conversation_id}`;
  }

  return (
    <a className={tCss.hyperlink} href={url} target="_blank" rel="noreferrer">
      {text}
    </a>
  );
};

const hyperlinkReadOnlyDecorator = {
  strategy: (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState,
  ) => {
    // @ts-ignore
    contentBlock.findEntityRanges(meta => {
      const entityKey = meta.getEntity();
      if (!entityKey) {
        return false;
      }
      const entityType = contentState.getEntity(entityKey)?.getType();

      return entityType === 'HYPERLINK';
    }, callback);
  },
  component: HyperlinkReadOnlyComponent,
};

type LinkProps = {
  entityKey: string;
  contentState: ContentState;
  children: any;
  target?: string;
  decoratedText: string;
};

function findLinkEntities(
  contentBlock: ContentBlock,
  // @ts-ignore
  callback,
  contentState: ContentState,
) {
  const links = linkify.match(contentBlock.getText());

  if (links) {
    links.map(link => callback(link.index, link.lastIndex));
  }
}

const isEmail = (link: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(link);
};

const Link = (props: LinkProps) => {
  const url = props.decoratedText;
  return (
    <a
      href={`${isEmail(url) ? 'mailto:' : ''}${url}`}
      className={tCss.hyperlink}
      target={'_blank'}
      rel="noreferrer"
    >
      {props.children}
    </a>
  );
};

const LinkDecoratorEntry = {
  strategy: findLinkEntities,
  component: Link,
};
