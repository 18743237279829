import {useEffect, useRef, useCallback} from 'react';

interface PollingConfig {
  // base interval between polls
  interval: number;
  enabled?: boolean;
  maxAttempts?: number;
  // flag to enable exponential backoff on errors
  retryBackoff?: boolean;
  // maximum interval for backoff
  maxInterval?: number;
  stopIf?: () => boolean;
  stopFn?: () => void;
}

interface PollingState {
  isPolling: boolean;
  attempts: number;
  currentInterval: number;
}

export function usePolling(fetchFn: () => void, config: PollingConfig) {
  const {
    interval,
    enabled = true,
    retryBackoff = true,
    maxAttempts = +Infinity,
    maxInterval = 3000,
    stopIf,
    stopFn,
  } = config;

  const timeoutRef = useRef<NodeJS.Timeout>();
  const stateRef = useRef<PollingState>({
    isPolling: false,
    attempts: 0,
    currentInterval: interval,
  });

  const calculateNextInterval = useCallback(() => {
    if (!retryBackoff) {
      return interval;
    }

    const nextInterval = Math.min(
      interval * Math.pow(2, stateRef.current.attempts),
      maxInterval,
    );
    return nextInterval;
  }, [interval, maxInterval, retryBackoff]);

  const stopPolling = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
    stateRef.current.isPolling = false;
    stopFn?.();
  }, [stopFn]);

  const poll: () => Promise<void> = useCallback(async () => {
    if (!enabled || stateRef.current.attempts >= maxAttempts) {
      stopPolling();
      return;
    }

    await fetchFn();
    if (stopIf?.()) {
      stopPolling();
      return;
    }
  }, [enabled, maxAttempts, fetchFn, stopIf, stopPolling]);

  const onSuccess = useCallback(() => {
    // attempt and interval reset on success
    stateRef.current.attempts = 0;
    stateRef.current.currentInterval = interval;

    // Schedule next poll on success
    if (stateRef.current.isPolling) {
      timeoutRef.current = setTimeout(poll, interval);
    }
  }, [interval, poll]);

  const onError = useCallback(() => {
    stateRef.current.attempts++;
    stateRef.current.currentInterval = calculateNextInterval();

    // Schedule next poll with backoff on error
    if (stateRef.current.isPolling) {
      timeoutRef.current = setTimeout(poll, stateRef.current.currentInterval);
    }
  }, [calculateNextInterval, poll]);

  useEffect(() => {
    if (enabled && !stateRef.current.isPolling) {
      stateRef.current.isPolling = true;
      poll();
    }
  }, [enabled, poll]);

  return {
    attempts: stateRef.current.attempts,
    onSuccess,
    onError,
  };
}
