import invariant from 'invariant';

import uniqueId from 'utils/uniqueId';
import {
  ChatbotMode,
  ChatbotState,
  ChatbotAction,
  OutgoingMessage,
  Message,
  ApiMessage,
} from 'types';

export function reduceChatbot(
  state: ChatbotState,
  action: ChatbotAction,
): ChatbotState {
  switch (action.type) {
    case 'start_loading':
      return {
        ...state,
        isLoading: true,
      };

    case 'clear_conversation':
      return {...initialState, faqs: state.faqs};

    case 'receive_error':
      return {
        ...state,
        error: action.payload,
      };

    // this is temporary to get rid of the nanoid sessionId
    case 'receive_session_id':
      return {
        ...state,
        sessionId: action.payload,
      };

    case 'set_chatbot_mode':
      return {
        ...state,
        mode: action.payload,
      };

    case 'start_conversation': {
      let isDone = false;
      let chatbotMode = state.mode;
      const agents = {...state.agents};

      let messages: Array<Message> = action.payload.messages.map(message => {
        if (message.user_type === 'candidate') {
          return {
            id: uniqueId(),
            timeCreated: new Date(),
            direction: 'outgoing',
            text: message.text,
          };
        } else {
          return {
            ...message,
            id: uniqueId(),
            timeCreated: new Date(),
            direction: 'incoming',
          };
        }
      });

      for (const message of messages) {
        isDone =
          message.direction === 'incoming' &&
          message.type === 'conversation-end';

        // (marcos) DUPE
        if (message.direction === 'incoming') {
          chatbotMode = nextChatbotMode(chatbotMode, message.type);

          // ingest agents
          if (message.type === 'lat-agent-join') {
            agents[message.metadata.agent_id] = {
              id: message.metadata.agent_id,
              name: message.metadata.agent_handle,
              avatar_url: message.metadata.agent_avatar,
            };
          }
        }
      }

      return {
        ...state,
        isLoading: false,
        isDone,
        mode: chatbotMode,
        conversation: {
          ...action.payload,
          messages: messages,
        },
        agents,
      };
    }

    case 'receive_conversation':
      let isReceiveDone = false;
      let chatbotMode = state.mode;
      const agents = {...state.agents};

      const {isSendPending, isPolling} = state.poll;
      const {isPollResponse} = action.payload;

      // not consuming poll response if send API request is pending
      if (isSendPending && isPollResponse) {
        return state;
      }

      const stateMessages = state.conversation?.messages || [];
      let finalMessages: Array<ApiMessage> = action.payload.messages;

      // consuming only messages that are not yet in state
      // message_id is guaranteed to be present in the last message as it would be from bot
      if (isPolling === true) {
        const {messages: incomingMessages = []} = action.payload;
        const lastStateMessageId =
          stateMessages.length > 0
            ? stateMessages[stateMessages.length - 1].message_id
            : '';

        const lastMessageIndex = incomingMessages.findIndex(
          message => message.message_id === lastStateMessageId,
        );

        if (lastMessageIndex === -1) {
          finalMessages = incomingMessages;
        } else {
          finalMessages = incomingMessages.slice(lastMessageIndex + 1);
        }
      }

      let messagesReceived: Array<Message> = finalMessages.map(message => {
        if (message.user_type === 'candidate') {
          return {
            id: uniqueId(),
            timeCreated: new Date(),
            direction: 'outgoing',
            text: message.text,
          };
        } else {
          return {
            ...message,
            id: uniqueId(),
            timeCreated: new Date(),
            direction: 'incoming',
          };
        }
      });

      for (const message of messagesReceived) {
        isReceiveDone =
          message.direction === 'incoming' &&
          message.type === 'conversation-end';

        if (message.direction === 'incoming') {
          window?.gtag?.('event', 'incoming_nlu_message', {type: message.type});

          // ingest agents
          if (message.type === 'lat-agent-join') {
            agents[message.metadata.agent_id] = {
              id: message.metadata.agent_id,
              name: message.metadata.agent_handle,
              avatar_url: message.metadata.agent_avatar,
            };
          }

          if (message.type === 'lat-wait-start') {
            state.webSocketUrl = message.web_socket_url;
          }

          chatbotMode = nextChatbotMode(chatbotMode, message.type);
        }
      }

      if (state.conversation) {
        messagesReceived = state.conversation.messages.concat(messagesReceived);
      }

      if (state.mode !== chatbotMode) {
        window?.gtag?.('event', 'conversation_mode_change', {
          mode: chatbotMode,
        });
      }

      return {
        ...state,
        isLoading: false,
        isDone: isReceiveDone,
        mode: chatbotMode,

        // @ts-ignore
        conversation: {
          ...state.conversation,
          messages: messagesReceived,
        },
        agents,
        poll: {
          ...state.poll,
          isSendPending: false,
        },
      };

    case 'receive_conversation_error': {
      const error = action.payload;
      let errorMessage;
      switch (error.response.status) {
        case 503:
          errorMessage =
            "I'm sorry, the chatbot is currently undergoing maintenance and cannot respond. You can try later";
          break;
        case 500:
          errorMessage =
            "I'm sorry, there is an error preventing your message from being processed. Please try later.";
          break;
        default:
          errorMessage =
            'We apologize for the inconvenience, but something went wrong on our end. Please try again later.';
      }

      const message: Message = {
        direction: 'incoming',
        id: uniqueId(),
        timeCreated: new Date(),
        text: errorMessage,
        type: 'plain-text',
        recipient_id: '',
        message_id: uniqueId(),
        metadata: {
          event_type: 'sense_message_action',
        },
      };

      // this action is initiated by nlu/send calls which happen _after_ a conversation has been
      // created on the client.
      if (state.conversation == null) {
        throw new Error(
          'cannot receive a conversation error without an existing conversation',
        );
      }

      const messages = state.conversation.messages.concat([message]);

      return {
        ...state,
        isLoading: false,
        isDone: false,
        conversation: {
          ...state.conversation,
          messages,
        },
      };
    }

    case 'toggle_show_faq':
      return {
        ...state,
        showFaq: !state.showFaq,
      };

    case 'post_message': {
      const conversation = state.conversation;
      invariant(
        conversation,
        'Attempted to post a message without a loaded conversation.',
      );

      const newMessage: OutgoingMessage = {
        id: uniqueId(),
        timeCreated: new Date(),
        direction: 'outgoing',
        text: action.payload,
      };

      return {
        ...state,
        isLoading: true,
        showBanner: false,
        conversation: {
          ...conversation,
          messages: [...conversation.messages, newMessage],
        },
        poll: {
          ...state.poll,
          isSendPending: true,
        },
      };
    }

    case 'toggle_jobs_view': {
      return {
        ...state,
        showJobsView: action.payload,
      };
    }

    case 'toggle_mute_chime': {
      return {
        ...state,
        muteChime: action.payload,
      };
    }

    case 'update_file': {
      return {
        ...state,
        file: action.payload,
      };
    }

    case 'receive_faqs': {
      return {
        ...state,
        faqs: action.payload,
      };
    }

    case 'end_conversation': {
      return {
        ...state,
        isDone: true,
      };
    }

    case 'receive_agency_config': {
      return {
        ...state,
        agencyConfig: action.payload,
      };
    }

    case 'preview_loading_action': {
      return {
        ...state,
        transcript: {
          error: null,
          loading: true,
          data: [],
        },
      };
    }

    case 'preview_receive_success_action': {
      return {
        ...state,
        transcript: {
          error: null,
          loading: false,
          data: action.payload,
        },
      };
    }

    case 'preview_receive_error_action': {
      return {
        ...state,
        transcript: {
          error: action.payload,
          loading: false,
          data: [],
        },
      };
    }

    case 'start_poll': {
      return {
        ...state,
        poll: {
          ...state.poll,
          isPolling: true,
        },
      };
    }

    case 'update_poll_data': {
      return {
        ...state,
        poll: {
          ...state.poll,
          isPolling: true,
          lastMessageId: action.payload,
        },
      };
    }

    case 'stop_poll': {
      return {
        ...state,
        poll: {
          ...state.poll,
          isPolling: false,
          lastMessageId: undefined,
        },
      };
    }
  }

  return state;
}

// api messages can transition the chatbot into other modes
// i.e. in LAT a lat-wait-start message puts the chatbot into
// the lat_wait mode which draws a cancel button in the ui
// and triggers a websocket connection. likewise, transitioning
// out of that mode will disconnect the websocket. this encodes
// the state transitions we can make
function nextChatbotMode(
  currentMode: ChatbotMode,
  messageType: ApiMessage['type'],
): ChatbotMode {
  let nextMode = currentMode;
  if (currentMode === 'nlu') {
    if (messageType === 'lat-wait-start') {
      nextMode = 'lat_wait';
    }
  } else if (currentMode === 'lat_wait') {
    if (messageType === 'lat-agent-join') {
      nextMode = 'lat';
    } else if (messageType === 'lat-agent-drop') {
      nextMode = 'lat_terminating';
    }
  } else if (currentMode === 'lat') {
    if (messageType === 'lat-agent-drop') {
      nextMode = 'lat_terminating';
    }
  } else if (['lat_terminating', 'lat_canceled'].includes(currentMode)) {
    if (messageType != null) {
      nextMode = 'nlu';
    }
  }
  if (currentMode !== nextMode) {
    console.log(`changing chatbot mode from ${currentMode} to ${nextMode}`);
  }

  return nextMode;
}

export const initialState: ChatbotState = {
  isLoading: false,
  isDone: false,
  conversation: null,
  error: null,
  isSms: false,
  inputValue: '',
  chatId: {value: 'bad id', type: 'chat_code'},
  sessionId: 'bad id',
  showJobsView: false,
  muteChime: false,
  file: null,
  showFaq: false,
  faqs: [],
  agencyConfig: {
    chatbot_white_label_frontend: false,
  },
  agents: {},
  mode: 'nlu', // default mode
  webSocketUrl: null,
  showBanner: true,
  transcript: {
    error: null,
    loading: false,
    data: [],
  },
  poll: {
    isPolling: false,
    lastMessageId: undefined,
    isSendPending: false,
  },
};
